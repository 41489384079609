import React, { Suspense } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import lazyImport from './lazyImport';
import { SemipolarLoading } from 'react-loadingg';

import './App.css';

const Services = lazyImport('./screens/Services');
const About = lazyImport('./screens/About');
const Home = lazyImport('./screens/Home');
const Contact = lazyImport('./screens/Contact');

const LoadingMessage = () => <SemipolarLoading />;

const App = () => {
  return (
    <Router>
      <div>
        <Suspense fallback={<LoadingMessage />}>
          <Switch>
            <Route path="/services">
              <Services />
            </Route>

            <Route path="/about">
              <About />
            </Route>

            <Route path="/contact">
              <Contact />
            </Route>

            <Route>
              <Home />
            </Route>
          </Switch>
        </Suspense>
      </div>
    </Router>
  );
};

export default App;
